import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

const About = () => {
  return (
    <Layout>
      <section className='flex flex-col mx-6 mt-12 md:w-2/5 md:mx-auto shadow rouneded p-5 md:p-10'>
        <h3 className='text-2xl md:text-3xl font-semibold text-gray-800'>Hi, I'm Ankit Singh.</h3>
        <div className='space-y-5 blog-about text-base md:text-lg text-gray-700 md:leading-relaxed mt-5'>
          <p>
            I'm the human behind this site. I'm a software engineer by the day and a thinking machine rest of the times.
        </p>
          <p>
            The mission here is to look past the contemporary discourse (mostly pessimistic) about technology and think in fresh and holistic ways.
            My interests includes <a href='https://bitcoin.org/bitcoin.pdf' target='blank' className='underline'>decentralised</a> trust based systems, <a href='https://www.longevity.vc/' target='blank' className='underline'>longevity</a> tech, multimedia rich <a href='https://vimeo.com/36579366' target='blank' className='underline'>education</a> and digital health.
            I strongly agree that there is an urgent need for more people to not just use but to <a href='https://balajis.com/the-purpose-of-technology/' target='' className='underline'>evangelize</a> technology so that humanity can be <a href='https://www.youtube.com/watch?v=lX-K63pVPTM' target='' className='underline'>optimistic</a> about the long term.
        </p>
          <p>
            Please don't hesitate to reach out if you have some ideas along the lines of above topics.
          </p>
          <div className='flex flex-col space-y-5'>
            <Link href="mailto:ankitsingh26548@gmail.com" className='rounded bg-blueGray-500 text-white mt-2 py-1 px-5 mx-auto'>Email</Link>
            <Link to="https://twitter.com/inktsg" target='blank' className='rounded bg-blueGray-500 text-white mt-2 py-1 px-5 mx-auto'>Twitter</Link>
          </div>
        </div >
      </section>
    </Layout >
  )
}

export default About;