import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div className='bg-blueGray-200 flex flex-col justify-center items-center p-2 md:p-2'>
      <Link className='site-title text-5xl md:text-6xl text-center text-blueGray-700 block mt-8 capitalize' to="/">{siteTitle}</Link>
      <p className='site-caption font-bold text-sm md:text-base text-blueGray-700'>meditations in technology</p>
      <div className='flex flex-col md:flex-row md:justify-center md:space-x-8 items-center mt-4 mb-2'>
        <Link to='/' className='text-base font-medium md:text-2xl text-blueGray-700'>Start Here</Link>
        <Link to='/on-my-mind' className='text-base font-medium md:text-2xl text-blueGray-700'>On My Mind</Link>
        {/* <Link to='/about' className='text-base md:text-2xl text-blueGray-700'>Explain Like I'm Adult</Link> */}
        {/* <Link to='/about' className='text-base font-medium md:text-2xl text-blueGray-700'>Influences</Link> */}
        <Link to='/about' className='text-base font-medium md:text-2xl text-blueGray-700'>About</Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
