import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "@fontsource/ibm-plex-serif"
import "@fontsource/inter"

import Header from "./header"
import "../styles/layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className='site-main'>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className='content-main'>{children}</div>
      <footer>
        <div class="footer-trademark text-xl font-bold text-blueGray-700 text-black text-center mt-10">
          © <span>Techimadions {new Date().getFullYear()}</span>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
